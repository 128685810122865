<template>
  <section class="text-left w-full pt-base">
    <div class="vx-card__title mb-5">
      <h4 class="mb-4">Thank you for signing up!</h4>
    </div>
    <vs-list>

      <vs-list-item title="Create an account" >
        <template slot="avatar">
          <vs-avatar icon="icon-check" icon-pack="feather" class="mr-5" color="success" />
        </template>
      </vs-list-item>

      <vs-list-item title="Confirm your email">
        <template slot="avatar">
          <vs-avatar icon="icon-check" icon-pack="feather" class="mr-5" />
        </template>
        <template slot="subtitle">
          <div class="text-xs mt-1">
            <p>To get access to your account please verify your email address by following the instruction we've sent to your registered email.</p>
            <!-- <p>If you didn't receive the email verification, please <a href="#" @click.prevent="verify">click here</a>.</p> -->
          </div>
        </template>
      </vs-list-item>

      <vs-list-item subtitle="Time to get started!" class="opacity-50">
        <template slot="avatar">
          <vs-avatar icon="icon-check" icon-pack="feather" class="mr-5" />
        </template>
      </vs-list-item>
    </vs-list>
    
  </section>
</template>

<script>
export default {
  methods: {
    verify() {
      // this.$store.dispatch('auth/verify');
    }
  }
}
</script>
